import React from "react"

const SharedWhyChooseUsContent = ({ alt }) => {
  const points = [
    {
      title: "Experience counts",
      body: `Extensive knowledge and experience from our background
		working with leading UK startups and large companies
		alike. Our founders previously held leadership roles at BBC Worldwide, Paypal and HSBC, among others`,
    },
    {
      title: "Work directly with the do-ers",
      body: `You'll be co-developing your ideas directly with experienced engineers and software engineering managers.
	  Get answers quickly, so you can make decisions without friction`,
    },
    {
      title: "Proven Methodology",
      body: `We use an iterative approach to build software incrementally, with regular check-ins with your key stakeholders.
	  Agile methodologies mean the process is streamlined and flexible, allowing you to evolve and grow your vision with us`,
    },
    // {
    //   title: "It's the details that count",
    //   body: `We understand that its the little details, the micro-interactions,
    // 	that can make a good app really special. Sprinkling magic dust is our speciality!`,
    // },
    {
      title: "Thinking Long Term",
      body: `Our focus is to deliver high quality, maintainable code.
		That means taking into account your current goals as well as long term vision`,
    },
  ]

  return (
    <>
      <h2 className="section-title larger">Why Choose Us?</h2>
      <h3 className="display-4">
        {alt
          ? `${points.length} reasons why we are different`
          : `Agencies are a dime a dozen, here's how we're different`}
      </h3>
      <div className="space30" />
      <div id="choose-us-accordion" className="accordion-wrapper simple">
        {points.map((point, index) => (
          <AccordionCard title={point.title} body={point.body} index={index} />
        ))}
      </div>
      {/* /.accordion-wrapper */}
    </>
  )
}

const AccordionCard = ({ title, body, index }) => {
  return (
    <div className="card">
      <div className="card-header" id={`accordion-heading-${index}`}>
        <h5>
          <button
            data-toggle="collapse"
            data-target={`#accordion-collapse-${index}`}
            aria-expanded={index === 0 ? `true` : `false`}
            aria-controls={`accordion-collapse-${index}`}
          >
            {title}
          </button>
        </h5>
      </div>
      {/* /.card-header */}
      <div
        id={`accordion-collapse-${index}`}
        className={`collapse ${index === 0 && `show`}`}
        aria-labelledby={`accordion-heading-${index}`}
        data-parent="#choose-us-accordion"
      >
        <div className="card-body">
          <p>{body}</p>
        </div>
        {/* /.card-body */}
      </div>
      {/* /.collapse */}
    </div>
  )
}

export default SharedWhyChooseUsContent
