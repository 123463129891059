// @ts-check
import React from "react"

import Layout from "../components/layout/Layout"
import WhatWeDo from "../partials/WhatWeDo"
import "./index.css"
import HomeHowItWorks from "../partials/HomeHowItWorks"
import HomeWhyChooseUs from "../partials/HomeWhyChooseUs"
import ImageHeader from "../components/ImageHeader"

const IndexPage = () => (
  <Layout transparentHeader title="edamame studio">
    <ImageHeader
      imgSrc="/images/bg/office.jpg"
      title="Makers of marvellous apps"
      lead="We're a London based independent studio and consultancy, specialising in React Native"
      wave
    />

    <WhatWeDo />
    {/* <HomeHowItWorks /> */}
    <HomeWhyChooseUs />
  </Layout>
)

export default IndexPage
