// @ts-check
import React from "react"
import FadeUp from "../components/FadeUp"
import SharedWhyChooseUsContent from "./SharedWhyChooseUsContent"

const HomeWhyChooseUs = () => {
  return (
    <div className="wrapper light-wrapper">
      <div className="container inner">
        <div className="row align-items-center">
          <div className="col-lg-6 order-lg-2 text-center">
            <FadeUp>
              <figure>
                <img
                  src="#"
                  srcSet="/snowlake/images/concept/concept17.png 1x, /snowlake/images/concept/concept17@2x.png 2x"
                  alt=""
                />
              </figure>
            </FadeUp>
          </div>
          {/*/column */}
          <div className="space20 d-md-none" />
          <div className="space50 d-none d-md-block d-lg-none" />
          <div className="col-lg-6 pr-60 pr-md-15">
            <SharedWhyChooseUsContent />
          </div>
          {/*/column */}
        </div>
        {/*/.row */}
      </div>
      {/* /.container */}
    </div>
  )
}

export default HomeWhyChooseUs
