import React from "react"
import FadeUp from "../components/FadeUp"

const WhatWeDo = () => (
  <div className="wrapper white-wrapper">
    <div className="container" style={{ paddingTop: 40 }}>
      <div className="text-center">
        <h2 className="title-bg bg-default color-default">What We Do</h2>
      </div>
      <div className="space10"></div>
      <h3 className="display-3 text-center">
        Our goal is to craft engaging mobile apps{" "}
        <br className="d-none d-lg-block" />
        that your users will love
      </h3>
      <div className="space30"></div>
      <div className="row gutter-60 text-center align-items-end">
        <div className="col-md-4">
          <FadeUp delay={300}>
            <figure className="mb-30">
              <img
                src="#"
                srcSet="/snowlake/images/concept/concept14.png 1x, /snowlake/images/concept/concept14@2x.png 2x"
                alt=""
              />
            </figure>
          </FadeUp>
          <h4>Hand-crafted Apps</h4>
          <p className="mb-15">
            With years of experience building iOS and Android apps, from MVPs to
            large scale projects - we give your ideas the attention they deserve
          </p>
          {/* <div className="text-center">
            <a href="#" className="more">
              Learn more
            </a>
          </div> */}
        </div>
        <div className="space20 d-md-none"></div>
        <div className="col-md-4">
          <FadeUp delay={500}>
            <figure className="mb-30">
              <img
                src="#"
                srcSet="/snowlake/images/concept/concept19.png 1x, /snowlake/images/concept/concept19@2x.png 2x"
                alt=""
              />
            </figure>
          </FadeUp>
          <h4>Full-stack solutions</h4>
          <p className="mb-15">
            Apps are often more than just a pretty face - we can build the
            backend services that drive your app, or integrate into APIs you may
            already have
          </p>
          {/* <div className="text-center">
            <a href="#" className="more">
              Learn more
            </a>
          </div> */}
        </div>
        <div className="space20 d-md-none"></div>
        <div className="col-md-4">
          <FadeUp delay={700}>
            <figure className="mb-30">
              <img
                src="#"
                srcSet="/snowlake/images/concept/concept18.png 1x, /snowlake/images/concept/concept18@2x.png 2x"
                alt=""
              />
            </figure>
          </FadeUp>
          <h4>Deep Specialist Expertise</h4>
          <p className="mb-15">
            We've shipped products facing millions of users, and with that comes
            expertise to get your projects out there, and, to keep them running
            smooth
          </p>
          {/* <div className="text-center">
            <a href="#" className="more">
              Learn more
            </a>
          </div> */}
        </div>
      </div>
      <div className="space50 d-none d-md-block"></div>
    </div>
  </div>
)

export default WhatWeDo
